import styled from '@emotion/styled/macro';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';

import { ProjectHeader } from '../../components/Projecten/ProjectHeader';
import { ProjectIntro } from '../../components/Projecten/ProjectIntro';
import { ProjectQuote } from '../../components/Projecten/ProjectQuote';
import { ProjectTextContainer } from '../../components/Projecten/ProjectTextContainer';
import { ProjectVideo } from '../../components/Projecten/ProjectVideo';
import { ProjectsCarousel } from '../../components/ProjectsCarousel/ProjectsCarousel';
import { Root } from '../../components/Root';
import { Column } from '../../components/UI/Column';
import { Row } from '../../components/UI/Row';
import { Text } from '../../components/UI/Text';
import { Breakpoint } from '../../types';
import { getImageByAlt } from '../../utils/getImageByAlt';

export const query = graphql`
  {
    allSanityProject(filter: {Client: {eq: "Gelatoqueen"}}) {
      edges {
        node {
          Gallery {
            Alt
            asset {
              fluid(maxWidth: 2000) {
                aspectRatio
                sizes
                base64
                src
                srcSet
              }
            }
          }
        }
      }
    }
  }
`;

const gelatoqueen = ({ data }) => {
  const images = data.allSanityProject.edges[0].node.Gallery;

  const headerImage = getImageByAlt(images, 'gelatoqueen-header');
  const gelatoqueenMobile1 = getImageByAlt(images, 'gelatoqueen-mobile');
  const gelatoqueenMobile2 = getImageByAlt(images, 'gelatoqueen-mobile-2');
  const gelatoqueenMobile3 = getImageByAlt(images, 'gelatoqueen-mobile-3');

  return (
    <>
      <ProjectHeader image={headerImage.asset.fluid.src} />
      <Root>
        <ProjectIntro tags="#ux #react #webapp #ecommerce #2019" title="Ijstaarten bestellen bij Gelatoqueen" />

        <StyledRow >
          <Column direction="row" breakpoints={{ [Breakpoint.Phone]: 4 }}>
            <MobileImg fluid={gelatoqueenMobile1.asset.fluid} alt={gelatoqueenMobile1.Alt} />
          </Column>
          <Column direction="row" breakpoints={{ [Breakpoint.Phone]: 4 }}>
            <MobileImg fluid={gelatoqueenMobile2.asset.fluid} alt={gelatoqueenMobile2.Alt} />
          </Column>
          <Column direction="row" breakpoints={{ [Breakpoint.Phone]: 4 }}>
            <MobileImg fluid={gelatoqueenMobile3.asset.fluid} alt={gelatoqueenMobile3.Alt} />
          </Column>
        </StyledRow>

        <ProjectTextContainer>
          <Text mode="projectText">
            Gelatoqueen wou graag de mogelijkheid aanbieden om ijstaarten via hun bestaande website te bestellen. Crossworx to the rescue!
          </Text>
          <br /><br />
          <Text mode="tag">
            Zin gekregen in een ijstaart? Bestel er eentje op &nbsp;
                <Link to={'https://gelatoqueen-bestellen.be'} target="_blank">
              <u>Gelatoqueen-bestellen.be</u>
            </Link>
            {' '}-{' '}
            Of surf naar &nbsp;
            <Link to={'https://gelatoqueen.be'} target="_blank">
              <u>Gelatoqueen.be</u>
            </Link>
          </Text>
        </ProjectTextContainer>

        <ProjectVideo color="#9FD8C1" source="372600525" />

        <ProjectQuote
          title='"Discover the forgotten taste of ice-cream"'
          tag="Jelle & Jonathan, Gelatoqueen"
          text="Ieder dessert bouwen we eigenhandig op van bodem tot afwerking. Er gaat niets boven een ijsdessert die je doet 
          terudenken aan je kindertijd. Glutenvrij, lactosevrij & vegan!" />

        <ProjectsCarousel carouselTitle="Er is meer." forProject={true} />
      </Root>
    </>
  );
};

export default gelatoqueen;

const StyledRow = styled(Row)`
  width: 100%;
  margin: 7rem auto;
`;
const MobileImg = styled(Img)`
  height: 100%;
  width: 100%;
  margin: 0 auto;
`;